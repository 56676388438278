import './bootstrap';
import Alpine from 'alpinejs'
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

import.meta.glob([
    '../assets/images/**'
]);

window.Alpine = Alpine;
window.PhotoSwipeLightbox = PhotoSwipeLightbox;
window.PhotoSwipe = PhotoSwipe;

Alpine.start();
